import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":"","outlined":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"depressed":"","fab":"","loading":_vm.loading,"small":""},on:{"click":_vm.approveHandler}},'v-btn',Object.assign({}, _vm.$attrs, attrs),false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("check")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Approve')))])])}
var staticRenderFns = []

export { render, staticRenderFns }