import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,_vm._b({scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","color":"primary","depressed":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.$t('Actions'))+" ("+_vm._s(_vm.count)+")")])])]}}])},'v-menu',_vm.$attrs,false),[_c(VList,[_c(VListItem,[_c(VListItemTitle,{staticClass:"d-flex flex-column flex-gap-16 my-2"},[_vm._t("default")],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }