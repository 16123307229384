import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable',{scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('Commissions')))]),_c(VDivider)],1)]},proxy:true},{key:"scrollable-content",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c('div',{staticClass:"px-4"},[_c('resource-grid',{attrs:{"options":_vm.options,"show-select":"","items":_vm.grid.data,"hide-default-footer":"","selected-items":_vm.selected},on:{"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event},"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"small":"","to":{ name: 'admin-update', params: { id: item.user_id } }}},[_vm._v(" "+_vm._s(_vm.getShortId(item.user_id))+" ")])]}},{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"small":"","to":{ name: 'single-order', params: { id: item.order_number } }}},[_vm._v(" "+_vm._s(item.order_number)+" ")])]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('span',[_vm._v(_vm._s(item.percentage)+"%")])])]}},{key:"item.commission_on_shipping",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.commission_on_shipping ? 'check' : 'close'))])],1)]}},{key:"item.commission_on_tax",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.commission_on_tax ? 'check' : 'close'))])],1)]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c(VIcon,[_vm._v(_vm._s(item.approved ? 'check' : 'close'))])],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount)?_c('span',[_vm._v(_vm._s(_vm.$format.money.withStoreCurrency(item.amount)))]):_vm._e()]}},{key:"item.revoked_amount",fn:function(ref){
var item = ref.item;
return [(item.revoked_amount)?_c('span',[_vm._v(" "+_vm._s(_vm.$format.money.withStoreCurrency(item.revoked_amount))+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.updated_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-gap-8"},[(_vm.canApproveItem(item).value)?_c('commission-action-approve',{attrs:{"value":item,"disabled":_vm.grid.loading},on:{"onSuccess":_vm.reloadCollection}}):_c('commission-action-unapprove',{attrs:{"value":item,"disabled":_vm.grid.loading},on:{"onSuccess":_vm.reloadCollection}}),(_vm.canRevokeItem(item).value)?_c('commission-action-revoke',{attrs:{"value":item,"disabled":_vm.grid.loading},on:{"onSuccess":_vm.reloadCollection}}):_vm._e(),(_vm.canRecalculate)?_c('commission-action-recalculate',{attrs:{"value":item,"disabled":_vm.grid.loading},on:{"onSuccess":_vm.reloadCollection}}):_vm._e()],1)]}}],null,true),model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}),_c(VDivider),(!heightExceeded)?_c('div',{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-space-between"},[_c('commission-mass-actions',{attrs:{"dense":_vm.$vuetify.breakpoint.mdAndDown,"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"onSuccess":_vm.reloadCollection}}),_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()],1)]}},{key:"sticky-bottom",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c(VDivider),(heightExceeded)?_c(VFooter,{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-space-between"},[_c('commission-mass-actions',{attrs:{"dense":_vm.$vuetify.breakpoint.mdAndDown,"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"onSuccess":_vm.reloadCollection}}),_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }