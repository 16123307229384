import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('form-validator-wrapper',{on:{"submit":_vm.recalculate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c(VCard,[_c(VCardTitle,{staticClass:"justify-space-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Commission recalculation'))+" ")]),_c(VIcon,{attrs:{"size":"32"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1),_c(VDivider),_c(VCardText,[_c('div',{staticClass:"d-flex flex-column flex-gap-16"},[_c('commission-percentage',{model:{value:(_vm.recalculateCommission),callback:function ($$v) {_vm.recalculateCommission=$$v},expression:"recalculateCommission"}}),_c('commission-on-shipping',{model:{value:(_vm.recalculateCommission),callback:function ($$v) {_vm.recalculateCommission=$$v},expression:"recalculateCommission"}}),_c('commission-on-tax',{model:{value:(_vm.recalculateCommission),callback:function ($$v) {_vm.recalculateCommission=$$v},expression:"recalculateCommission"}})],1)]),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-end pa-3"},[_c(VBtn,{attrs:{"color":"primary","disabled":!valid,"depressed":"","type":"submit","loding":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Recalculate'))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }